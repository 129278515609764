angular.module('app').component('cardType', {

    bindings: {
        card: '='
    },

    template: '<i class="fa fa-2x {{$ctrl.icon}}" aria-hidden="true"></i>',

    controller: function () {
        this.icon = undefined;

        switch (this.card) {
            case "VISA":
                this.icon = "fa-cc-visa";
                break;
            case "AMEX":
                this.icon = "fa-cc-amex";
                break;
            case "MASTERCARD":
                this.icon = "fa-cc-mastercard";
                break;
            case "DINERS_CLUB":
                this.icon = "fa-cc-diners-club";
                break;
        }
    }
});